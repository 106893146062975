<template>
    <span class="aname" :class="{disabled: props.feed.disabled, 'has-unread': props.feed.unread != 0}" v-on:click="openFeed(props.feed.id)">
        <img v-if="$parent.reading == props.feed.id" src="/img/icons/weather_newmoon.svg" />
        <img v-else src="/img/icons/weather_fullmoon.svg" />
        <span class="name">
            {{ props.feed.title }}
        </span>
        <span class="count">
            {{ props.feed.unread }}
        </span>
    </span>
</template>

<script setup>
import { Eventbus } from '@/components/eventbus';

const props = defineProps({
    feed: Object,
});

const openFeed = (id) => {
    history.pushState({}, null, `/read/${id}`);

    // push state does not call popstate automatically
    // window.onpopstate();

    Eventbus.emit('openFeed', id);
};
</script>
