<template>
    <tr>
        <td>
            <div class="selectwrapper">
                <select v-model="feed.subscription_folder_id">
                    <option
                        v-for="folder in folders"
                        :value="folder.id"
                        v-text="folder.name"
                    ></option>
                </select>
            </div>
        </td>
        <td
            v-text="feed.title"
        ></td>
        <td>
            <button v-on:click="deleteSubscription">
                Delete
            </button>
        </td>
    </tr>
</template>

<script setup>
import { watch } from "vue";
import { Eventbus } from '@/components/eventbus';
import { useFetch } from "@/components/useFetch.js";

const props = defineProps({
    feed: Object,
    folders: Array,
    postRoute: String,
});

const deleteSubscription = async () => {
    if (! confirm(`Are you sure you want to stop subscribing to ${props.feed.title}?`)) {
        return;
    }

    const res = await useFetch(props.postRoute.replace('replace-me', props.feed.id), {
        method: 'DELETE',
    });

    if (res.ok) {
        Eventbus.emit('updateFeeds');
    } else {
        alert('Could not delete subscription');
    }
};

watch(
    props.feed,
    async () => {
        const res = await useFetch(props.postRoute.replace('replace-me', props.feed.id), {
            method: 'POST',
            body: props.feed,
        });

        if (res.ok) {
            Eventbus.emit('updateFeeds');
        } else {
            alert('Could not save update');
        }
    },
    {
        deep: true,
    }
);
</script>
