import { createApp } from 'vue';

import AddFeed from './components/addFeed.vue';
import AddFolder from './components/addFolder.vue';
import Feed from './components/feed.vue';
import FeedItem from './components/feedItem.vue';
import Folders from './components/folders.vue';
import FolderItem from './components/folderItem.vue';
import Subscriptions from './components/subscriptions.vue';
import SubscriptionItem from './components/subscriptionItem.vue';

const components = {
    'add-feed': AddFeed,
    'add-folder': AddFolder,
    'feed': [
        Feed,
        {
            'feed-item': FeedItem,
        },
    ],
    'folders': [
        Folders,
        {
            'folder-item': FolderItem,
        },
    ],
    'subscriptions': [
        Subscriptions,
        {
            'subscription-item': SubscriptionItem,
        },
    ],
};

let n = 0;
for (const tag in components) {
    for (const el of document.querySelectorAll(tag)) {
        const component = Array.isArray(components[tag]) ? components[tag][0] : components[tag];

        const app = createApp({
            template: el.outerHTML,
            name: `${tag} ${++n}`,
        });
        app.component(tag, component);
        if (Array.isArray(components[tag])) {
            for (const childTag in components[tag][1]) {
                app.component(childTag, components[tag][1][childTag]);
            }
        }
        app.mount(el);
    }
}
