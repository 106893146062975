<template>
    <div class="add-form">
        <div class="wrap">
            <input type="text" class="input" v-model="folder" placeholder="Cool Folder Name" />
            <button class="btn" :disabled="loading" v-on:click="addFolder">Add</button>
        </div>

        <small class="text-error" v-if="errorMessage" v-text="errorMessage"></small>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { Eventbus } from '@/components/eventbus';
import { useFetch } from "@/components/useFetch.js";

const props = defineProps({
    route: String,
});

const loading = ref(false);
const folder = ref('');
const errorMessage = ref('');

const addFolder = async () => {
    if (loading.value === true) {
        return;
    }

    loading.value = true;

    const res = await useFetch(props.route, {
        method: 'POST',
        body: {
            folder: folder.value,
        }
    });

    if (res.ok) {
        errorMessage.value = '';

        Eventbus.emit('updateFeeds');

        folder.value = '';
    } else {
        const output = await res.json();

        errorMessage.value = output.message;
    }

    loading.value = false;
};
</script>
