<template>
    <div class="add-form">
        <div class="wrap">
            <input type="text" class="input" v-model="feedurl" placeholder="https://goodblog.com/rss" />
            <button class="btn" :disabled="loading" v-on:click="addSubscription">Add</button>
        </div>

        <small class="text-error" v-if="errorMessage" v-text="errorMessage"></small>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { Eventbus } from '@/components/eventbus';
import { useFetch } from "@/components/useFetch.js";

const props = defineProps({
    route: String,
});

const loading = ref(false);
const feedurl = ref('');
const errorMessage = ref('');

const addSubscription = async () => {
    if (loading.value === true) {
        return;
    }

    loading.value = true;

    const res = await useFetch(props.route, {
        method: 'POST',
        body: {
            feedurl: feedurl.value,
        }
    });

    const output = await res.json();

    if (res.ok) {
        errorMessage.value = '';

        Eventbus.emit('refreshAndOpenFeed', {
            feed: output.data.id,
            folder: output.data.subscription_folder_id,
        });

        feedurl.value = '';
    } else {
        errorMessage.value = output.message;
    }

    loading.value = false;
};
</script>
