<template>
    <em v-if="loading">loading</em>
    <em v-else-if="sidebarContents.value.folders.length == 0 && sidebarContents.value.notInAFolder.length == 0">not subscribed to anything yet</em>
    <template v-else>
        <table>
            <thead>
                <th>Folder</th>
                <th>Feed</th>
                <th width="100px"></th>
            </thead>
            <tbody v-if="sidebarContents.value.notInAFolder.length > 0">
                <subscription-item
                    v-for="feed in sidebarContents.value.notInAFolder"
                    :key="`feed-${feed.id}`"
                    :feed="feed"
                    :folders="folders"
                    :postRoute="props.postRoute"
                ></subscription-item>
            </tbody>
            <tbody v-for="folder in sidebarContents.value.folders" :key="`folder-${folder.id}`">
                <subscription-item
                    v-for="feed in folder.feeds"
                    :key="`feed-${feed.id}`"
                    :feed="feed"
                    :folders="folders"
                    :postRoute="props.postRoute"
                ></subscription-item>
            </tbody>
        </table>

        <template v-if="emptyFolders.length">
            <hr>

            <h4>Empty Folders</h4>

            <table>
                <thead>
                    <th>Folder</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="folder in emptyFolders">
                        <td
                            v-text="folder.name"
                        ></td>
                        <td width="100px">
                            <button v-on:click="deleteFolder(folder)">
                                Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
    </template>
</template>

<script setup>
import { reactive, ref } from "vue";
import { Eventbus } from '@/components/eventbus';
import { useFetch } from "@/components/useFetch.js";
import debounce from 'lodash/debounce';

const props = defineProps({
    getRoute: String,
    postRoute: String,
    emptyFolderDeleteRoute: String,
});

const sidebarContents = reactive({});
const loading = ref(true);
let folders = [];
let emptyFolders = [];

const updateFolders = debounce(async function () {
    loading.value = true;

    const res = await useFetch(props.getRoute)
    sidebarContents.value = await res.json();

    folders = [
        {
            id: '',
            name: '',
        }
    ];

    emptyFolders = [];

    for (const folder of sidebarContents.value.folders) {
        folders.push({
            id: folder.id,
            name: folder.name,
        });

        if (folder.feeds.length == 0) {
            emptyFolders.push({
                id: folder.id,
                name: folder.name,
            });
        }
    }

    loading.value = false;
}, 500);

updateFolders();

Eventbus.on('updateFeeds', (subscription) => {
    updateFolders();
});

const deleteFolder = async (folder) => {
    if (! confirm(`Are you sure you want to delete the folder ${folder.name}?`)) {
        return;
    }

    const res = await useFetch(props.emptyFolderDeleteRoute.replace('replace-me', folder.id), {
        method: 'DELETE',
    });

    if (res.ok) {
        Eventbus.emit('updateFeeds');
    } else {
        alert('Could not delete folder');
    }
};
</script>
