export const useFetch = (url, info) => {
    // Default headers
    if (!info) {
        info = {};
    }
    if (!info.headers) {
        info.headers = {};
    }
    info.headers["X-Requested-With"] = "XMLHttpRequest";
    info.headers["Accept"] = "application/json";

    // XSRF TOKEN
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    if (token) {
        info.headers["X-CSRF-TOKEN"] = token;
    }
    // Transform body into required format and set header where necessary
    if (info.body) {
        if (info.body instanceof FormData) {
            // let the browser figure out the correct content type
        } else if (typeof info.body === "object") {
            info.body = JSON.stringify(info.body);
            info.headers["Content-Type"] = "application/json";
        }
    }

    return fetch(url, info);
};
