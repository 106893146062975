<template>
    <em v-if="loading">loading</em>
    <em v-else-if="sidebarContents.value.folders.length == 0 && sidebarContents.value.notInAFolder.length == 0">not subscribed to anything yet</em>
    <ul v-else>
        <li v-if="sidebarContents.value.notInAFolder.length > 0">
            <ul class="open">
                <li v-for="feed in sidebarContents.value.notInAFolder">
                    <folder-item :feed="feed" />
                </li>
            </ul>
        </li>
        <li v-for="folder in sidebarContents.value.folders" :key="`folder-${folder.id}`">
            <span class="aname" v-on:click="openFolder(folder.id)">
                <img v-if="open == folder.id" src="/img/icons/arrows_minus.svg" />
                <img v-else src="/img/icons/arrows_plus.svg" />
                <span class="name">
                    {{ folder.name }}
                </span>
                <span class="count" :class="{ 'has-unread': folder.unread != 0}">
                    {{ folder.unread }}
                </span>
            </span>
            <ul :class="{'open': open == folder.id}">
                <li v-for="feed in folder.feeds" :key="`feed-${feed.id}`">
                    <folder-item :feed="feed" />
                </li>
            </ul>
        </li>
    </ul>
</template>

<script setup>
import { reactive, ref } from "vue";
import { Eventbus } from '@/components/eventbus';
import { useFetch } from "@/components/useFetch.js";
import debounce from 'lodash/debounce';

const props = defineProps({
    route: String,
});

const open = ref('');
const sidebarContents = reactive({});
const loading = ref(true);
const reading = ref(0);

const openFolder = (id) => {
    if (open.value == id) {
        open.value = '';
    } else {
        open.value = id;
    }
};

const updateFolders = debounce(async function () {
    // don't worry about this, it looks rubbish
    // loading.value = true;
    const res = await useFetch(props.route)
    sidebarContents.value = await res.json();
    loading.value = false;
}, 500);

updateFolders();
setInterval(updateFolders, 5000);

Eventbus.on('refreshAndOpenFeed', (subscription) => {
    updateFolders();

    if (subscription.folder) {
        open.value = subscription.folder;
    }

    Eventbus.emit('openFeed', subscription.feed);
});
Eventbus.on('isReadingFeedId', (feed) => {
    reading.value = feed;
});
Eventbus.on('openFeed', (feed) => {
    reading.value = feed;
});

Eventbus.on('openFolderBySubscriptionId', (subscriptionID) => {
    var found = false;

    for (var folder of sidebarContents.value.folders) {
        for (var feed of folder.feeds) {
            if (feed.id == subscriptionID) {
                found = true;
            }
        }
        if (found) {
            open.value = folder.id;
            break;
        }
    }

    if (! found) {
        window.location.reload();
    }
});

defineExpose({
    reading,
});
</script>
