<template>
        <div class="filters" @change="filterUpdate">
            <div class="selectwrapper">
                <select v-model="sortDirection">
                    <option value="desc">Showing Newest First</option>
                    <option value="asc">Showing Oldest First</option>
                </select>
            </div>
            <div class="selectwrapper">
                <select v-model="withRead">
                    <option value="0">Hiding Read Posts</option>
                    <option value="1">Showing Read Posts</option>
                </select>
            </div>
        </div>

        <div class="feed">
            <div class="title" v-if="feedTitle">
                <h2>{{ feedTitle }}</h2>
                <button @click="markAllRead">Mark All Read</button>
                <a v-if="feedURL" :href="feedURL">Visit Website</a>
            </div>
            <p v-if="! items.length" class="waiting">
                <span v-if="loading">Loading...</span>
                <span v-else-if="reading == ''">Click a feed to get reading</span>
                <span v-else>Looks like you've read this one, try another feed if you want.</span>
            </p>
            <feed-item
                v-for="item in items"
                :key="item.id"
                :item="item"
                :reading="reading"
                :routes="routes"
            />
            <div v-if="moreItems && items.length" class="loading" ref="scrollLoader">
                <span v-if="scrollLoading">Loading...</span>
                <span v-else @click="scrollLoad">Scroll or click to load more</span>
            </div>
            <div v-else-if="items.length" class="loading">
                <span>End of items</span>
            </div>
        </div>

        <button class="btn back-to-top" @click="scrollToTop">Top</button>
</template>

<script setup>
import { onUpdated, reactive, ref, watch } from "vue";
import { Eventbus } from '@/components/eventbus';
import { useFetch } from "@/components/useFetch.js";

const props = defineProps({
    routes: Object,
    class: String,
});

const feedTitle = ref('');
const feedURL = ref('');
const items = ref([]);
const loading = ref(false);
const moreItems = ref(true);
const reading = ref('');
const scrollLoader = ref();
const scrollLoading = ref(false);
const sortDirection = ref(localStorage.getItem('rssky_sort') ?? 'desc');
const withRead = ref(localStorage.getItem('rssky_with_read') ?? '0');

const getFeedDetails = async () => {
    feedTitle.value = '';
    feedURL.value = '';

    const res = await useFetch(props.routes.subscriptionDetails.replace('replace-me', reading.value));

    const details = (await res.json()).data;

    feedTitle.value = details.title;
    feedURL.value = details.homepage;
};

let fetchAbort, fetchAbortSignal;

const getFeedItems = (reset) => {
    // abort the old one if there was one
    if (fetchAbort !== undefined) {
        fetchAbort.abort();
    }
    // reinitialise the abort controller for each new request
    if ("AbortController" in window) {
        fetchAbort = new AbortController;
        fetchAbortSignal = fetchAbort.signal;
    }

    const params = {};

    if (reset) {
        // empty feed store
        items.value = [];
        moreItems.value = true;
    } else if (items.value.length) {
        // for pagination, grab last item id
        params.after = items.value[items.value.length - 1].id;
    }

    // detect sorting and inclusion of read items
    params.sort = sortDirection.value;
    params.with_read = withRead.value;

    // build URL
    // - relative needs a base specifying
    var url = new URL(props.routes.subscriptionItems.replace('replace-me', reading.value), window.location.origin);
    url.search = new URLSearchParams(params).toString();

    loading.value = true;

    return useFetch(url, { signal: fetchAbortSignal })
        .then((res) => res.json())
        .then(({data}) => {
            if (data === undefined) {
                return;
            }

            const newItems = data;
            if (newItems && newItems.length) {
                items.value = items.value.concat(newItems);
            } else {
                moreItems.value = false;
            }

            if (reset) {
                scrollToTop()
            }
            loading.value = false;
        });
};

const openFeed = async (feedId) => {
    loading.value = true;

    reading.value = parseInt(feedId);
    Eventbus.emit('isReadingFeedId', feedId);

    await getFeedDetails();

    getFeedItems(true);
};

Eventbus.on('openFeed', openFeed);

window.onpopstate = () => {
    var subscriptionID = window.location.pathname.substr(6);

    Eventbus.emit('openFolderBySubscriptionId', subscriptionID);
    Eventbus.emit('isReadingFeedId', subscriptionID);

    openFeed(subscriptionID);
};

if (window.location.pathname.length > 6) {
    window.onpopstate();
}

const saveFiltersAndFilter = () => {
    // store current values in local storage
    localStorage.setItem('rssky_sort', sortDirection.value);
    localStorage.setItem('rssky_with_read', withRead.value);

    getFeedItems(true);
};

watch(sortDirection, saveFiltersAndFilter);
watch(withRead, saveFiltersAndFilter);

const scrollLoad = () => {
    scrollLoading.value = true;
    getFeedItems()
        .then(() => {
            scrollLoading.value = false;
        });
};

// we only care about one entry at a time so splat
const loadingObserver = new IntersectionObserver(([entry], observer) => {
    if (scrollLoading.value) {
        return;
    }

    if (entry.intersectionRatio === 1) {
        scrollLoad();
    }
}, {
    threshold: 1
});

onUpdated(() => {
    if (scrollLoader.value) {
        loadingObserver.observe(scrollLoader.value);
    }
});

const scrollToTop = () => {
    // browser can handle back to top by itself, https://twitter.com/simonhearne/status/1259927098040467457?s=20
    // but supporting no-subgrid means more targetted is better

    document.querySelector('.read .header').scrollIntoView({
        behaviour: 'smooth',
    });
};

const markAllRead = () => {
    var check1 = confirm("Are you sure you wish to mark everything in this view as read?");
    if (check1 !== false) {
        var check2 = confirm("Well if you are absolutely sure...");
        if (check2 !== false) {
            var check3 = confirm("Last chance to back out...");
            if (check3 !== false) {
                useFetch(props.routes.subscriptionMarkAllAsRead.replace('replace-me', reading.value))
                    .then(() => {
                        Eventbus.emit('markAllAsRead');
                    });
            }
        }
    }
};
</script>
