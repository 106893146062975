<template>
    <article v-if="storedItem.id" :data-id="storedItem.id" :class="{'is-read': isRead == 1}">
        <span class="date">{{ storedItem.date }}</span>
        <h3 v-if="storedItem.title" v-html="storedItem.title"></h3>
        <h3 v-else><i>untitled</i></h3>
        <div class="actions">
            <a v-if="storedItem.link.trim().length > 0" :href="storedItem.link" title="read on website">
                <img src="/img/icons/basic_globe.svg" />
            </a>
            <button v-if="isRead == 1" @click="isRead = 0" title="mark as unread">
                <img src="/img/icons/basic_eye_closed.svg" />
            </button>
            <button v-if="isRead == 0" @click="isRead = 1" title="mark as read">
                <img src="/img/icons/basic_eye.svg" />
            </button>
            <button v-if="hasOverflown" v-on:click="toggleExpanded">
                <img v-if="hasExpanded" src="/img/icons/arrows_shrink_vertical2.svg" />
                <img v-else src="/img/icons/arrows_stretch_vertical1.svg" />
            </button>
            <a v-if="storedItem.enclosure_url !== null" :href="storedItem.enclosure_url" title="Download media">
                <img src="/img/icons/basic_server_download.svg" />
            </a>
        </div>
        <div class="content-wrapper">
            <figure v-if="storedItem.enclosure_url !== null && storedItem.enclosure_type.startsWith('audio/')" class="audio-content">
                <figcaption>Listen:</figcaption>
                <audio controls :src="storedItem.enclosure_url"></audio>
            </figure>

            <div class="text-content"
                :class="{expanded: hasExpanded, 'expandable-content': hasOverflown}"
                ref="articleContent"
                v-if="storedItem.content && storedItem.content.trim().length"
                v-html="storedItem.content"
            ></div>
            <div class="text-content" v-else><i>no content</i></div>
        </div>
    </article>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import { Eventbus } from '@/components/eventbus';
import { useFetch } from "@/components/useFetch.js";

const props = defineProps({
    item: Object,
    reading: Number,
    routes: Object,
});

const storedItem = ref({});

const articleContent = ref();
const hasOverflown = ref(false);
const hasExpanded = ref(false);

const toggleExpanded = () => {
    hasExpanded.value = !hasExpanded.value;
};

let fetchAbort, fetchAbortSignal;

const isRead = computed({
    get: function () {
        return storedItem.value.read;
    },
    set: (newValue) => {
        // abort the old one if there was one
        if (fetchAbort !== undefined) {
            fetchAbort.abort();
        }
        // reinitialise the abort controller for each new request
        if ("AbortController" in window) {
            fetchAbort = new AbortController;
            fetchAbortSignal = fetchAbort.signal;
        }

        var url = new URL(props.routes.subscriptionItemUpdate.replace('replace-me', props.reading).replace('replace-item', storedItem.value.id), window.location.origin);
        url.search = new URLSearchParams({
            read: newValue
        }).toString();


        return useFetch(url, { method: "POST", signal: fetchAbortSignal })
            .then((res) => res.json())
            .then(({data}) => {
                storedItem.value = data;
            });
    }
});

onMounted(function () {
    storedItem.value = props.item;

    nextTick().then(() => {
        hasOverflown.value = articleContent.value.scrollHeight > articleContent.value.clientHeight;
    });
});

Eventbus.on('markAllAsRead', () => {
    storedItem.value.read = true;
});
</script>
